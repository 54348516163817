import React from 'react';

import {
  NoochsContainer,
  NoochWrapper,
  NoochsHeading,
  NoochTitle,
  NoochCard,
  NoochImg,
  NoochInfo,
  NoochDesc,
  NoochPrice,
  NoochButton
} from './NoochElements';
const Nooch = () => {
  return (
    <NoochsContainer>
      <NoochsHeading>Nooch Flavor</NoochsHeading>
      <NoochWrapper>
                <NoochCard>
              <NoochInfo>
    <NoochTitle>Nutritional Facts</NoochTitle>
   <NoochDesc>238 Cal per Package </NoochDesc>
   <NoochDesc>12g Fat per Package </NoochDesc>
   <NoochDesc>22g Carbs per Package </NoochDesc>
   <NoochDesc>7.8g Protein per Package </NoochDesc>

              </NoochInfo>
            </NoochCard>
            <NoochCard>
              <NoochInfo>
    <NoochTitle>Ingredients</NoochTitle>
   <NoochDesc>Chickpeas, Vegan Mayo(Canola Oil, Water, Modified Food Starch, Distilled Vinegar, Flavors) Nutritional Yeast, Tamari Soy Sauch</NoochDesc>
   <NoochDesc>Contains Soy</NoochDesc>
              </NoochInfo>
            </NoochCard>
      </NoochWrapper>
    </NoochsContainer>
  );
};

export default Nooch;
