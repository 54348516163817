import React from 'react';
import { Nav, NavLink, NavIcon, Bars, dividers, Dividers} from './NavbarElements';
import {AiOutlineShoppingCart} from 'react-icons/ai';
const Navbar = ({ toggle }) => {
  return (
    <>
      <Nav>
        <NavLink to='/Menu'>  Menu  </NavLink>
        <NavLink to='/'>  Home  </NavLink>
        {/* <NavLink to='/Catering' >  Catering  </NavLink> */}
        <NavLink to='/Products'>  Products  </NavLink>
        <NavLink to='/AboutUs'>  About Us  </NavLink>
        <NavLink to='/CallToAction'> Call to Action </NavLink>
        {/* <NavLink to='/Contact'>  Contact Us  </NavLink> */}
        {/* <NavLink to='/SocialMedia'>  Social Media  </NavLink> */}
        <NavIcon>
            <AiOutlineShoppingCart size="2em" onClick={toggle}/>
        </NavIcon>
      </Nav>
    </>
  );
};

export default Navbar;

//MENU FARM PRODUCTS ABOUT US CONTACT SOCIAL MEDIA 