import React from 'react';

import {
  ProductsContainer,
  ProductWrapper,
  ProductsHeading,
  ProductTitle,
  ProductCard,
  ProductImg,
  ProductInfo,
  ProductDesc,
  ProductPrice,
  ProductButton
} from './ProductsElements';
const ProductsExport = () => {
  return (
    <ProductsContainer>
      <ProductsHeading>Three Flavors!</ProductsHeading>
      <ProductWrapper>
            <ProductCard>
              <ProductInfo>
                <ProductTitle>Original Vegan Tunafish Spread</ProductTitle>
                <ProductDesc>A Taste of the Sea and lemon make this Vegan Tuna Fish Salad fresh and delectable!</ProductDesc>
                <ProductButton to='OG'>Nutrition Facts</ProductButton>
                </ProductInfo>
            </ProductCard>
                <ProductCard>
              <ProductInfo>
                <ProductTitle>Vegan Spicy Tuna Salad</ProductTitle>
                <ProductDesc>With Habañero!</ProductDesc>
                <ProductButton to='ST'>Nutrition Facts</ProductButton>
                </ProductInfo>
            </ProductCard>
                <ProductCard>
              <ProductInfo>
    <ProductTitle>Nooch Salad</ProductTitle>
   <ProductDesc>With Nutritional Yeast, this B12 packed salad will surely satisfy your savery side!</ProductDesc>
   <ProductButton to='Nooch'>Nutrition Facts</ProductButton>
   <ProductTitle></ProductTitle>
   </ProductInfo>
            </ProductCard>
   <ProductCard>
              <ProductInfo>
              <ProductTitle>Survey</ProductTitle>
   <ProductButton to='https://forms.gle/nvYataBJWRieUEGS9'>Complete this survey for a coupon!</ProductButton>
              </ProductInfo>
            </ProductCard>
      </ProductWrapper>
    </ProductsContainer>
  );
};

export default ProductsExport;
