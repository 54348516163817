import React, { useState } from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import {
  CallToActionHeroContainer,
  CallToActionHeroContent,
  CallToActionHeroItems,
  CallToActionHeroH1,
  CallToActionHeroP,
  CallToActionHeroBtn
} from './CallToActionHeroElements';

const CallToActionHero = ({isM}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
    <CallToActionHeroContainer>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <CallToActionHeroContent>
        <CallToActionHeroItems>
          <CallToActionHeroH1>This is a call for you to expand vegan options in your resturant!</CallToActionHeroH1>
        </CallToActionHeroItems>
      </CallToActionHeroContent>
    </CallToActionHeroContainer>
    </div>
  );
};

export default CallToActionHero;
