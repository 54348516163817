import React from 'react';
import { Link } from 'react-router-dom';
import {
  AboutUsContainer,
  AboutUsWrapper,
  AboutUsHeading,
  AboutUsTitle,
  AboutUsCard,
  AboutUsImg,
  AboutUsInfo,
  AboutUsDesc,
  AboutUsPrice,
  AboutUsButton
} from './AboutUsElements';
import Headshot from './LewieHeadshot.png';



const AboutUsPeople = () => {
  return (
    <div>
    <AboutUsContainer>
      <AboutUsWrapper>
            <AboutUsCard>
             <AboutUsImg src={Headshot} alt={"Lewie"} /> 
              <AboutUsInfo>
                <AboutUsTitle>Lewis Herman</AboutUsTitle>
                <AboutUsDesc>Lewis Herman is a vegan philospher! He has written the book The Physics of Morals about his own system of ethics. He enjoys board games and swimming in the lake. Link to His Website Below! I aim to help resturants and bars service the Vegan marketplace. Veganism is a good fit for me and I hope you will try it out sometime! Veganism is good for the planet and is good for your health! Please email me if you would like to try becoming a vegan! </AboutUsDesc>
                <a href="http://www.lewisherman.com/" target="_blank">LewisHerman.com</a>
              </AboutUsInfo>
            </AboutUsCard>
      </AboutUsWrapper>
    </AboutUsContainer>
    </div>
  );
};

export default AboutUsPeople;