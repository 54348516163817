import React from 'react';

import {
  STsContainer,
  STWrapper,
  STsHeading,
  STTitle,
  STCard,
  STImg,
  STInfo,
  STDesc,
  STPrice,
  STButton
} from './STelements';
const ST = () => {
  return (
    <STsContainer>
      <STsHeading>Spicy Tuna Flavor</STsHeading>
      <STWrapper>
                <STCard>
              <STInfo>
    <STTitle>Nutrition Facts</STTitle>
   <STDesc>240 Cal per Package </STDesc>
   <STDesc>12g Fat per Package </STDesc>
   <STDesc>25g Carbs per Package </STDesc>
   <STDesc>7.8g Protein per Package </STDesc>
              </STInfo>
            </STCard>
            <STCard>
              <STInfo>
    <STTitle>Ingredients</STTitle>
   <STDesc>Chickpeas, Vegan Mayo(Canola Oil, Water, Modified Food Starch, Distilled Vinegar, Flavors) SeaWeed, Habañero Hot Sauce</STDesc>
              </STInfo>
            </STCard>
      </STWrapper>
    </STsContainer>
    
  );
};

export default ST;
