import React, { useState } from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import {
  AboutUsHeroContainer,
  AboutUsHeroContent,
  AboutUsHeroItems,
  AboutUsHeroH1,
  AboutUsHeroP,
  AboutUsHeroBtn
} from './AboutUsHeroElements';

const AboutUsHero = ({isM}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AboutUsHeroContainer>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <AboutUsHeroContent>
        <AboutUsHeroItems>
          <AboutUsHeroH1>Meet the Team!</AboutUsHeroH1>
        </AboutUsHeroItems>
      </AboutUsHeroContent>
    </AboutUsHeroContainer>
  );
};

export default AboutUsHero;
