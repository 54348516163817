import React, { useState } from 'react';
import Footer from './Footer'
import Navbar from './Navbar';
import { GlobalStyle } from '../globalStyles';
import AboutUsHero from './AboutUsHero';
import AboutUsPeople from './AboutUsPeople';

const AboutUsPage = ({isMobile}) => {
  return (
    <div>
    <AboutUsHero isM={isMobile}/>
    <AboutUsPeople/>
    <Footer/>
    </div>
  );
};

export default AboutUsPage;
