import React from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';

import {
  OGsContainer,
  OGWrapper,
  OGsHeading,
  OGTitle,
  OGCard,
  OGImg,
  OGInfo,
  OGDesc,
  OGPrice,
  OGButton
} from './OGElements';
const OG = () => {
  return (
    <OGsContainer>
      <OGsHeading>Original Vegan Tuna Flavor</OGsHeading>
      <OGWrapper>
                <OGCard>
              <OGInfo>
    <OGTitle>Nutritional Facts</OGTitle>
   <OGDesc>240 Cal per Package </OGDesc>
   <OGDesc>12g Fat per Package </OGDesc>
   <OGDesc>25g Carbs per Package </OGDesc>
   <OGDesc>7.8g Protein per Package </OGDesc>
              </OGInfo>
            </OGCard>
            <OGCard>
              <OGInfo>
    <OGTitle>Ingredients</OGTitle>
   <OGDesc>Chickpeas, Vegan Mayo(Canola Oil, Water, Modified Food Starch, Distilled Vinegar, Flavors) SeaWeed, Lemon Zest</OGDesc>
              </OGInfo>
            </OGCard>
      </OGWrapper>
    </OGsContainer>
  );
};

export default OG;
