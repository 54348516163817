import React, { useState } from 'react';
import Footer from './Footer'
import Navbar from './Navbar';
import { GlobalStyle } from '../globalStyles';
import CallToActionPeople from './CallToActionPeople';
import CallToActionHero from './CallToActionHero';

const CallToActionPage = ({isMobile}) => {
  return (
    <div>
    <CallToActionHero isM={isMobile}/>
    <CallToActionPeople/>
    <Footer/>
    </div>
  );
};

export default CallToActionPage;
