import React from 'react';
import { Nav, NavLink, NavIcon, Bars, dividers, Dividers} from './NavbarElements';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  SideBtnWrap
} from './SidebarElements';

const MobileNavbar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarMenu>
      <Nav>
        <NavLink to='/Menu'>  Menu  </NavLink>
        <NavLink to='/'>  Home  </NavLink>
        {/* <NavLink to='/Catering' >  Catering  </NavLink> */}
        <NavLink to='/Products'>  Products  </NavLink>
        <NavLink to='/AboutUs'>  About Us  </NavLink>
        {/* <NavLink to='/Contact'>  Contact Us  </NavLink> */}
        {/* <NavLink to='/SocialMedia'>  Social Media  </NavLink> */}
        <NavIcon>
            <h1 size="2em" onClick={toggle}>Navigation</h1>
        </NavIcon>
      </Nav>
        <p>
          
        </p>
      {/* We need to put the shopping cart Here */}
      </SidebarMenu>
      <SideBtnWrap>
      </SideBtnWrap>
    </SidebarContainer>
  );
};

export default MobileNavbar;

//MENU FARM PRODUCTS ABOUT US CONTACT SOCIAL MEDIA 