import React, { useState } from 'react';
import MenuPhotos from './MenuPhotos'
import Footer from './Footer/'
import Navbar from './Navbar';
import { GlobalStyle } from '../globalStyles';
import MenuHero from './MenuHero';
const MenuPage = ({isM}) => {
  return (
    <div>
    <MenuHero isM={isM}/>
    <MenuPhotos/> 
    <Footer/>
    </div>
  );
};

export default MenuPage;
