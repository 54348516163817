import React from 'react';
import { Link } from 'react-router-dom';
import {
  CallToActionContainer,
  CallToActionWrapper,
  CallToActionHeading,
  CallToActionTitle,
  CallToActionCard,
  CallToActionImg,
  CallToActionInfo,
  CallToActionDesc,
  CallToActionPrice,
  CallToActionButton
} from './CallToActionElements';
import VeganBox from './VeganBox.png';



const CallToActionPeople = () => {
  return (
    <div>
    <CallToActionContainer>
      <CallToActionWrapper>
            <CallToActionCard>
             <CallToActionImg src={VeganBox} alt={"VeganBox"} /> 
              <CallToActionInfo>
                <CallToActionTitle>Vegan Box Solutions</CallToActionTitle>
                <CallToActionDesc>The lack of tasty and nutrious vegan options in resturants accross the northern Chicagoland Area has spurred us to create Vegan Box and Vecan Salads. The need for people to have a decent option besides vegetable salads and imitation meat makes us a viable and market ready solution. I would like to expand the vegan experience with my own knowledge of the vegan pallet. If you would like a consultation of how to improve vegan options, please email me at LewisHerman5@gmail.com.  </CallToActionDesc>
              </CallToActionInfo>
            </CallToActionCard>
      </CallToActionWrapper>
    </CallToActionContainer>
    </div>
  );
};

export default CallToActionPeople;