import React, { useState } from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import {
  ProductsHeroContainer,
  ProductsHeroContent,
  ProductsHeroItems,
  ProductsHeroH1,
  ProductsHeroP,
  ProductsHeroBtn
} from './ProductsHeroElements';

const ProductsHero = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ProductsHeroContainer>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <ProductsHeroContent>
        <ProductsHeroItems>
          <ProductsHeroH1>Product Info</ProductsHeroH1>
          {/* <ProductsHeroP>GoodStuff to eat!</ProductsHeroP>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdQ3EWA-k1_U1DKxQO8sU-Xb9l0eTuGEsBbMlZLbE69tK4iGg/viewform" target="_blank">
          <ProductsHeroBtn>Place Order</ProductsHeroBtn>
          </a> */}
        </ProductsHeroItems>
      </ProductsHeroContent>
    </ProductsHeroContainer>
  );
};

export default ProductsHero;