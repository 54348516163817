import React, { useState } from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import {
  ProductsPageHero1Container,
  ProductsPageHero1Content,
  ProductsPageHero1Items,
  ProductsPageHero1H1,
  ProductsPageHero1P,
  ProductsPageHero1Btn
} from './ProductsPageHero1Elements';
import MobileNavbar from '../MobileNavbar';

const ProductsPageHero1 = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    props.isMobile ? ( 
      <ProductsPageHero1Container>
      <MobileNavbar isOpen={isOpen} toggle={toggle} />
      <ProductsPageHero1Content>
        <ProductsPageHero1Items>
          <ProductsPageHero1H1> {props.name}</ProductsPageHero1H1>
        </ProductsPageHero1Items>
      </ProductsPageHero1Content>
    </ProductsPageHero1Container>) : (
    <ProductsPageHero1Container>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <ProductsPageHero1Content>
        <ProductsPageHero1Items>
          <ProductsPageHero1H1> {props.name}</ProductsPageHero1H1>
        </ProductsPageHero1Items>
      </ProductsPageHero1Content>
    </ProductsPageHero1Container>
  )
  )
};
export default ProductsPageHero1;