import React, { useState } from 'react';
import Hero from './Hero';
import Footer from './Footer'
import Navbar from './Navbar';
import { GlobalStyle } from '../globalStyles';
const HomePage = ({isM}) => {
  return (
    <div>
    <Hero isM={isM}/>
    <Footer/>
    </div>
  );
};

export default HomePage;
