import React, { useState } from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import {
  MenuHeroContainer,
  MenuHeroContent,
  MenuHeroItems,
  MenuHeroH1,
  MenuHeroP,
  MenuHeroBtn
} from './MenuHeroElements';

const MenuHero = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MenuHeroContainer>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <MenuHeroContent>
        <MenuHeroItems>
          <MenuHeroH1>Vecan: <br/> Delicious Vegan Salads!</MenuHeroH1>
          <MenuHeroP>WEBSITE UNDER CONSTRUCTION PLEASE ENJOY FEATURES AS THEY ARE ADDED!</MenuHeroP>
    
        </MenuHeroItems>
      </MenuHeroContent>
    </MenuHeroContainer>
  );
};

export default MenuHero;
