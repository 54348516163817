import Hero from './Hero';
import Footer from './Footer'
import Navbar from './Navbar';
import { GlobalStyle } from '../globalStyles';
import ProductsPageHero1 from './ProductsPageHero1/index';
import Nooch from './Nooch';
const NoochPage = ({isM}) => {
  return (
    <div>
    <ProductsPageHero1 name={"Nooch Salad Product Page"} isM={isM}/>
    <Nooch/>
    <Footer/>
    </div>
  );
};

export default NoochPage;