// import MenuPhoto1 from '../../images/goodstuff-menu-1.png';
// import MenuPhoto2 from '../../images/goodstuff-menu-2.png';
import {
    MenuPhotosContainer,
    MenuPhotosWrapper,
    MenuPhotosTitle,
    MenuPhotosCard,
    MenuPhotosImg,
    MenuPhotosInfo,
    MenuPhotosDesc,
    MenuPhotosPrice,
    MenuPhotosButton
  } from './MenuPhotosElements';
  //
const MenuPhotos = () => {
    return (
        <MenuPhotosWrapper>
          <div>
            <div>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
          {/* <img src = {MenuPhoto1}/> */}
            </div>
            <h1>Menu Coming Soon!</h1>
            <div>
          {/* <img src = {MenuPhoto2}/> */}
          <h1></h1>
            </div>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
            </div>
        </MenuPhotosWrapper>
    );
  };
  export default MenuPhotos;