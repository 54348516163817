import Hero from './Hero';
import Footer from './Footer'
import Navbar from './Navbar';
import { GlobalStyle } from '../globalStyles';
import ProductsPageHero1 from './ProductsPageHero1/index';
import ST from './ST';
const STPage = ({isM}) => {
  return (
    <div>
    <ProductsPageHero1 name={"Vegan Spicy Tuna Fish Salad Product"} isM={isM}/>
    <ST/>
    <Footer/>
    </div>
  );
};

export default STPage;