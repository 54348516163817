import Hero from './Hero';
import Footer from './Footer'
import Navbar from './Navbar';
import { GlobalStyle } from '../globalStyles';
import ProductsPageHero1 from './ProductsPageHero1/index';
import OG from './OG';
const OGPage = ({isM}) => {
  return (
    <div>
    <ProductsPageHero1 name={"Original Tuna Fish Salad Product Page"} isM={isM}/>
    <OG/>
    <Footer/>
    </div>
  );
};

export default OGPage;