import React, { useState } from 'react';
import Hero from './Hero';
import Footer from './Footer'
import Navbar from './Navbar';
import { GlobalStyle } from '../globalStyles';
import ProductsHero from './ProductsHero';
import Products from './ProductsExport';
const ProductPage = ({isM}) => {
  return (
    <div>
    <ProductsHero isM={isM}/>
    <Products/>
    <Footer/>
    </div>
  );
};

export default ProductPage;